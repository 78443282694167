import { API_ATTRIBUTE_MODEL, useAttributeListApi, type UseAttributeListApiArgs } from '@shared/api'
import { ATTRIBUTE_PARAMS } from '@shared/config'
import { useRouterParams } from '@shared/hooks'
import { getArrayParam } from '@shared/lib'

const useAttributesList = (param: ATTRIBUTE_PARAMS) => {
  const { params } = useRouterParams()

  const { isLoading, data: { results: attributes } = {} } = useAttributeListApi({
    model: API_ATTRIBUTE_MODEL.SKU,
    [ATTRIBUTE_PARAMS.ATTRIBUTE_TYPE]: getArrayParam(params[ATTRIBUTE_PARAMS.ATTRIBUTE_TYPE]),
    [ATTRIBUTE_PARAMS.OBJECT_GROUP]: getArrayParam(params[ATTRIBUTE_PARAMS.OBJECT_GROUP]),

    [ATTRIBUTE_PARAMS.CATEGORY]:
      param === ATTRIBUTE_PARAMS.CATEGORY
        ? getArrayParam(params[ATTRIBUTE_PARAMS.CATEGORY])
        : undefined,

    [ATTRIBUTE_PARAMS.CATEGORY_ISNULL]:
      param === ATTRIBUTE_PARAMS.CATEGORY_ISNULL
        ? getArrayParam(params[ATTRIBUTE_PARAMS.CATEGORY_ISNULL])
        : undefined,

    [ATTRIBUTE_PARAMS.SEARCH]: getArrayParam(params[ATTRIBUTE_PARAMS.SEARCH]),
  } as UseAttributeListApiArgs)

  return {
    isLoading,
    attributes,
  }
}

export { useAttributesList }
