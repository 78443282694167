import { EuiHeader, EuiHeaderSection, EuiHeaderSectionItem } from '@elastic/eui'
import { SaveButton } from '@shared/ui/form'
import { noShadow } from '@shared/ui/styles'

import { type AttributesHeaderProps } from '../types'

const AttributeHeader: ReactFc<AttributesHeaderProps> = ({
  isAttributeSubmitting,
  isSaveButtonActive,
  updateAttribute,
}) => {
  if (!isSaveButtonActive) {
    return null
  }

  return (
    <EuiHeader css={noShadow}>
      {/* Блок для смещения кнопки вправо без лишних стилей */}
      <EuiHeaderSection />

      <EuiHeaderSection>
        <EuiHeaderSectionItem>
          <SaveButton
            isActive={isSaveButtonActive}
            onClick={updateAttribute}
            isLoading={isAttributeSubmitting}
          />
        </EuiHeaderSectionItem>
      </EuiHeaderSection>
    </EuiHeader>
  )
}

export { AttributeHeader }
