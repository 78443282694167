import { EuiFlexGroup, EuiFlexItem, EuiHorizontalRule, EuiPanel } from '@elastic/eui'
import {
  useAttributeApi,
  useAttributeGroupOptions,
  useAttributeTypeOptions,
  useUpdateAttributeApi,
} from '@entities/attributes'
import { ATTRIBUTE_FIELD_NAMES, ATTRIBUTE_FIELDS, BUTTON } from '@shared/config'
import { getLocalDate } from '@shared/lib'
import { CheckboxInput, SelectInput, TextAreaInput, TextInput } from '@shared/ui/form'
import { AttributeInfoCategory } from '@widgets/settings'
import { Fragment } from 'react'
import { FormProvider } from 'react-hook-form'
import { useParams } from 'react-router-dom'

import { type AttributeInfoProps, type GetRow } from './types'

const AttributeInfo: ReactFc<AttributeInfoProps> = ({ methods }) => {
  const { id: attributeId } = useParams()
  const { data: attribute, isLoading: isAttributeLoading } = useAttributeApi(attributeId!)

  const { groupOptions, isLoading: isGroupOptionsLoading } = useAttributeGroupOptions()
  const { typeOptions, isLoading: isTypeOptionsLoading } = useAttributeTypeOptions()

  const { isSubmitting: isAttributeSubmitting } = useUpdateAttributeApi(attributeId!)

  // TODO: реализовать обработку кейса, когда атрибут не получен
  if (!attribute) {
    if (!isAttributeLoading) {
      console.error('Не удалось получить атрибут.')
    }

    return null
  }

  const getRow: GetRow = (name, field) => {
    return (
      <Fragment key={name}>
        <EuiPanel paddingSize="m" color="plain" borderRadius="none" hasShadow={false}>
          <EuiFlexGroup alignItems="center" gutterSize="s" key={name}>
            <EuiFlexItem grow={1}>{ATTRIBUTE_FIELD_NAMES[name]}</EuiFlexItem>

            <EuiFlexItem grow={3}>{field}</EuiFlexItem>
          </EuiFlexGroup>
        </EuiPanel>
        <EuiHorizontalRule margin="none" />
      </Fragment>
    )
  }

  const rowsConfig = [
    {
      name: ATTRIBUTE_FIELDS.UUID,
      field: <>{attribute.uuid}</>,
    },
    {
      name: ATTRIBUTE_FIELDS.IS_ENABLED,
      field: (
        <CheckboxInput
          name={ATTRIBUTE_FIELDS.IS_ENABLED}
          inputProps={{ disabled: isAttributeSubmitting }}
        />
      ),
    },
    {
      name: ATTRIBUTE_FIELDS.IS_DELETED,
      field: (
        <CheckboxInput
          name={ATTRIBUTE_FIELDS.IS_DELETED}
          inputProps={{ disabled: isAttributeSubmitting }}
        />
      ),
    },
    {
      name: ATTRIBUTE_FIELDS.NAME,
      field: (
        <TextInput name={ATTRIBUTE_FIELDS.NAME} inputProps={{ disabled: isAttributeSubmitting }} />
      ),
    },
    {
      name: ATTRIBUTE_FIELDS.ATTRIBUTE_TYPE,
      field: (
        <SelectInput
          name={ATTRIBUTE_FIELDS.ATTRIBUTE_TYPE}
          inputProps={{
            options: typeOptions,
            isLoading: isTypeOptionsLoading,
            disabled: isAttributeSubmitting,
          }}
        />
      ),
    },
    {
      name: ATTRIBUTE_FIELDS.IS_FEATURED,
      field: (
        <CheckboxInput
          name={ATTRIBUTE_FIELDS.IS_FEATURED}
          inputProps={{ disabled: isAttributeSubmitting }}
        />
      ),
    },
    {
      name: ATTRIBUTE_FIELDS.IS_REQUIRED,
      field: (
        <CheckboxInput
          name={ATTRIBUTE_FIELDS.IS_REQUIRED}
          inputProps={{ disabled: isAttributeSubmitting }}
        />
      ),
    },
    {
      name: ATTRIBUTE_FIELDS.DESCRIPTION,
      field: (
        <TextAreaInput
          name={ATTRIBUTE_FIELDS.DESCRIPTION}
          inputProps={{ disabled: isAttributeSubmitting }}
        />
      ),
    },
    {
      name: ATTRIBUTE_FIELDS.IS_PUBLIC,
      field: (
        <CheckboxInput
          name={ATTRIBUTE_FIELDS.IS_PUBLIC}
          inputProps={{ disabled: isAttributeSubmitting }}
        />
      ),
    },
    {
      name: ATTRIBUTE_FIELDS.OBJECT_GROUP,
      field: (
        <SelectInput
          name={ATTRIBUTE_FIELDS.OBJECT_GROUP}
          inputProps={{
            options: groupOptions,
            isLoading: isGroupOptionsLoading,
            disabled: isAttributeSubmitting,
          }}
        />
      ),
    },
    {
      name: ATTRIBUTE_FIELDS.IS_SKU,
      field: (
        <CheckboxInput
          name={ATTRIBUTE_FIELDS.IS_SKU}
          inputProps={{ disabled: isAttributeSubmitting }}
        />
      ),
    },
    {
      name: ATTRIBUTE_FIELDS.IS_NAME,
      field: (
        <CheckboxInput
          name={ATTRIBUTE_FIELDS.IS_NAME}
          inputProps={{ disabled: isAttributeSubmitting }}
        />
      ),
    },
    {
      name: ATTRIBUTE_FIELDS.IS_BRAND,
      field: (
        <CheckboxInput
          name={ATTRIBUTE_FIELDS.IS_BRAND}
          inputProps={{ disabled: isAttributeSubmitting }}
        />
      ),
    },
    {
      name: ATTRIBUTE_FIELDS.IS_PREVIEW,
      field: (
        <CheckboxInput
          name={ATTRIBUTE_FIELDS.IS_PREVIEW}
          inputProps={{ disabled: isAttributeSubmitting }}
        />
      ),
    },
    {
      name: ATTRIBUTE_FIELDS.SLUG,
      field: (
        <TextInput name={ATTRIBUTE_FIELDS.SLUG} inputProps={{ disabled: isAttributeSubmitting }} />
      ),
    },
    {
      name: ATTRIBUTE_FIELDS.UPDATED_AT,
      field: <>{getLocalDate(attribute.updated_at)}</>,
    },
    {
      name: ATTRIBUTE_FIELDS.CREATED_AT,
      field: <>{getLocalDate(attribute.created_at)}</>,
    },
    {
      name: ATTRIBUTE_FIELDS.CREATED_BY,
      field: <>{attribute.created_by}</>,
    },
  ]

  const rows = rowsConfig.map(({ name, field }) => {
    return getRow(name, field)
  })

  return (
    <EuiPanel color="subdued" borderRadius="none" paddingSize="none">
      <FormProvider {...methods}>
        <AttributeInfoCategory
          id={attribute.id.toString()}
          buttonContent={BUTTON.ALL_FIELDS}
          paddingSize="m"
          initialIsOpen
        >
          {rows}
        </AttributeInfoCategory>
      </FormProvider>
    </EuiPanel>
  )
}

export { AttributeInfo }
