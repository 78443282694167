import { type AttributeFieldNames } from './types'

export const APP_NAME = 'SKU HUB'
// TODO: Ссылка на публичный каталог, скорее всего будет в ENV
export const APP_PUBLIC_URL = 'https://google.com/search?q='

export const SIMPLE_LAYOUT_CONTENT_WIDTH = 268
export const SIMPLE_LAYOUT_LOGO_WIDTH = 210

export const SIDEBAR_OPEN_MIN_WIDTH = 200
export const SIDEBAR_CLOSED_MIN_WIDTH = 59
export const SIDEBAR_OPEN_LOGO_WIDTH = 118
export const SIDEBAR_CLOSED_LOGO_WIDTH = 21

export const ELEMENTS_XS_WIDTH = 240
export const ELEMENTS_S_WIDTH = 300
export const ELEMENTS_M_WIDTH = 400

export const CATALOG_CARD_SKELETON_AMOUNT = 9
export const CATALOG_CARD_IMAGE_SIZE = 200
export const CATALOG_TABLE_IMAGE_SIZE = 50

export enum CATALOG_VIEW {
  CARDS = 'Сетка',
  TABLE = 'Таблица',
}

export enum MESSAGE {
  ADD_GROUP = 'Создать группу',
  ATTRIBUTE_EMPTY_ERROR = 'Введите значение атрибута',
  BACK = 'Назад',
  BACK_MAIN = 'Вернуться на главную',
  CATEGORY_REQUIRED = 'Не выбрана категория',
  CREATE = 'Создать',
  EDIT = 'Редактировать',
  EDIT_ATTRIBUTE = 'Редактировать атрибут',
  EDIT_ATTRIBUTE_GROUP = 'Редактирование группы атрибутов',
  EMPTY = 'Ничего не найдено',
  EMPTY_CATALOGS = 'Каталоги отсутствуют',
  EMPTY_NAME = 'Без названия',
  EMPTY_VALUE = '–',
  ENTER_NAME = 'Введите название',
  ENTER_SKU = 'Введите артикул',
  FILE_UPLOAD = 'Перенесите сюда файл для загрузки',
  FILE_UPLOAD_CREATE_AT = 'Загружен',
  FILE_UPLOAD_SIZE = 'Размер',
  LOADING = 'Загрузка…',
  NEW_ATTRIBUTE = 'Новый атрибут',
  NO_PAGE_ERROR = 'Страница, которую вы ищите на найдена, попробуйте начать навигацию с главной страницы',
  PERMISSION_ERROR = 'У вас нет прав для просмотра этого раздела',
  SELECT_CATALOG = 'Выберите каталог',
  SERVER_ERROR = 'Ошибка сервера, попробуйте ещё раз',
}

export enum PLACEHOLDER {
  ATTRIBUTE_GROUP_NAME = 'Название группы',
  ATTRIBUTES_CATEGORY_SELECT = 'Выбрать категорию',
  COUNT = 'Позиция',
  FIND_ATTRIBUTE = 'Найти атрибут',
  FIND_SKU = 'Найти товар',
  SKU_NAME = 'Название товара',
  SKU_SKU = 'Артикул товара',
}

export enum BUTTON {
  ATTRIBUTES_CATEGORY_SELECT = 'Категории',
  ATTRIBUTES_LINK = 'Привязать',
  BACK = 'Назад',
  CREATE = 'Создать',
  DELETE = 'Удалить',
  DELETE_SELECTED = 'Удалить выбранные',
  EDIT = 'Редактировать',
  NEW_SKU = 'Новый товар',
  SAVE = 'Сохранить',
  SERVER_ERROR = 'Ошибка сервера, попробуйте ещё раз',
  SKU_ATTRIBUTES_TAB = 'Вкладка атрибуты',
  SKU_ATTRIBUTES_FILES_TAB = 'Вкладка изображения и файлы',
  SKU_ATTRIBUTES_TYPE_FILTER = 'Тип атрибутов',
  SWITCH_USER = 'Сменить профиль',
  ALL_FIELDS = 'Все поля',
}

export enum LABELS {
  ATTRIBUTES_ROOT_CATEGORY = 'Корневая категория',
}

export enum ATTRIBUTE_LABELS {
  SKU = 'SKU',
  NAME = 'PN',
  BRAND = 'Brand',
  PREVIEW = 'Preview',
}

export const ATTRIBUTE_FIELD_NAMES: AttributeFieldNames = {
  uuid: 'UUID',
  is_enabled: 'Активен',
  is_deleted: 'Удален',
  name: 'Название',
  attribute_type: 'Тип',
  is_featured: 'Is featured',
  is_required: 'Обязательный',
  description: 'Описание',
  is_public: 'Публичный',
  object_group: 'Группа',
  is_sku: 'SKU',
  is_name: 'Название продукта',
  is_brand: 'Бренд',
  is_preview: 'Превью',
  slug: 'Slug',
  updated_at: 'Обновлен',
  created_at: 'Создан',
  created_by: 'Создатель',
}

export enum ATTRIBUTE_FIELDS {
  UUID = 'uuid',
  IS_ENABLED = 'is_enabled',
  IS_DELETED = 'is_deleted',
  NAME = 'name',
  ATTRIBUTE_TYPE = 'attribute_type',
  IS_FEATURED = 'is_featured',
  IS_REQUIRED = 'is_required',
  DESCRIPTION = 'description',
  IS_PUBLIC = 'is_public',
  OBJECT_GROUP = 'object_group',
  IS_SKU = 'is_sku',
  IS_NAME = 'is_name',
  IS_BRAND = 'is_brand',
  IS_PREVIEW = 'is_preview',
  SLUG = 'slug',
  UPDATED_AT = 'updated_at',
  CREATED_AT = 'created_at',
  CREATED_BY = 'created_by',
}

export enum ATTRIBUTE_PARAMS {
  ATTRIBUTE_TYPE = 'attribute_type',
  CATEGORY = 'category',
  CATEGORY_ISNULL = 'category__isnull',
  MODEL = 'model',
  OBJECT_GROUP = 'object_group',
  OBJECT_GROUP_ISNULL = 'object_group__isnull',
  ORDERING = 'ordering',
  PAGE = 'page',
  SEARCH = 'search',
  SIZE = 'size',
}

export enum FILE_UPLOAD_ALLOWED {
  IMAGE = '.jpg, .jpeg, .png',
  DOCUMENTS = '.jpg, .jpeg, .png, .pdf, .doc, .docx, .xls, .xlsx, .txt, .ppt, .pptx, .mp4, .avi, .mov, .wmv, .mkv, .zip, .rar, .csv, .xml, .json',
}
