import { ATTRIBUTE_PARAMS } from '@shared/config'
import { useRouterParams } from '@shared/hooks'
import { useEffect } from 'react'

import { type UseCategoryFilterCheckboxArgs } from './types'

export const useCategoryFilterCheckbox = ({
  value,
  childNodes = [],
  isRoot = false,
}: UseCategoryFilterCheckboxArgs) => {
  const { hasParamValue, addParamValue, removeParamValue, toggleParamValue } = useRouterParams()

  // Проверяем, что все дочернии категории сейчас выделены
  const isAllchildNodesChecked = () => {
    if (childNodes.length > 0) {
      return childNodes.every((childNodesId) => {
        return hasParamValue(ATTRIBUTE_PARAMS.CATEGORY, childNodesId)
      })
    }

    return false
  }

  // Проверяем, что выделены некоторые из дочерних категорий
  const isIndeterminate = () => {
    if (childNodes.length > 0) {
      return childNodes.some((childNodesId) => {
        return hasParamValue(ATTRIBUTE_PARAMS.CATEGORY, childNodesId) && !isAllchildNodesChecked()
      })
    }

    return false
  }

  // Проверяем, что выделена текущая категория
  const isChecked = hasParamValue(
    isRoot ? ATTRIBUTE_PARAMS.CATEGORY_ISNULL : ATTRIBUTE_PARAMS.CATEGORY,
    value,
  )

  const handleToggle = () => {
    toggleParamValue(isRoot ? ATTRIBUTE_PARAMS.CATEGORY_ISNULL : ATTRIBUTE_PARAMS.CATEGORY, value)

    // Если выделена текущая категория или частично выделена, то при клике убираем все подкатегории
    if (!isChecked && !isIndeterminate()) {
      childNodes?.forEach((value) => {
        return addParamValue(ATTRIBUTE_PARAMS.CATEGORY, value)
      })
    } else {
      childNodes?.forEach((value) => {
        return removeParamValue(ATTRIBUTE_PARAMS.CATEGORY, value)
      })
    }
  }

  // Защита React route от изменения состояния до того, как он сделал рендер
  useEffect(() => {
    if (isAllchildNodesChecked()) {
      addParamValue(ATTRIBUTE_PARAMS.CATEGORY, value)
    }

    if (isChecked && !isAllchildNodesChecked() && childNodes.length > 0) {
      removeParamValue(ATTRIBUTE_PARAMS.CATEGORY, value)
    }
  }, [childNodes, isChecked, isIndeterminate])

  return {
    isIndeterminate,
    isChecked,
    handleToggle,
  }
}
