import { type EuiAccordionProps, EuiPanel } from '@elastic/eui'
import { Accordion } from '@shared/ui/accordion'

const AttributeInfoCategory: ReactFc<EuiAccordionProps> = (props) => {
  const { children } = props

  return (
    <EuiPanel color="transparent">
      <Accordion {...props}>{children}</Accordion>
    </EuiPanel>
  )
}

export { AttributeInfoCategory }
