import { EuiHeader, EuiHeaderSection, EuiHeaderSectionItem } from '@elastic/eui'
import { ATTRIBUTE_PARAMS, PLACEHOLDER } from '@shared/config'
import { useSelectableFilter } from '@shared/hooks'
import { Search, SelectablePopover } from '@shared/ui'
import { noShadow, useStyles } from '@shared/ui/styles'
import { AttributeActions } from '@widgets/settings'
import { useEffect } from 'react'

import { getStyles } from './styles'
import { type AttributesHeaderProps } from './types'

const AttributesHeader: ReactFc<AttributesHeaderProps> = ({
  optionsList: { typeOptions, groupOptions },
}) => {
  const styles = useStyles(getStyles)

  const {
    popoverOptions: popoverTypeOptions,
    checkParams: checkTypeParams,
    handleFilterChange: handleTypeFilterChange,
    handleFilterReset: handleTypeFilterReset,
  } = useSelectableFilter({ param: ATTRIBUTE_PARAMS.ATTRIBUTE_TYPE, options: typeOptions })

  const {
    popoverOptions: popoverGroupOptions,
    checkParams: checkGroupParams,
    handleFilterChange: handleGroupFilterChange,
    handleFilterReset: handleGroupFilterReset,
  } = useSelectableFilter({ param: ATTRIBUTE_PARAMS.OBJECT_GROUP, options: groupOptions })

  useEffect(() => {
    checkTypeParams()
    checkGroupParams()
  }, [])

  return (
    <EuiHeader css={noShadow}>
      <EuiHeaderSection css={styles.headerSection}>
        <EuiHeaderSectionItem>
          <Search searchParam="search" placeholder={PLACEHOLDER.FIND_ATTRIBUTE} />
        </EuiHeaderSectionItem>

        <EuiHeaderSectionItem>
          <SelectablePopover
            label="Тип данных"
            options={popoverTypeOptions}
            onChange={handleTypeFilterChange}
            searchable={false}
            onResetClick={handleTypeFilterReset}
          />
        </EuiHeaderSectionItem>

        <EuiHeaderSectionItem>
          <SelectablePopover
            label="Группы"
            options={popoverGroupOptions}
            onChange={handleGroupFilterChange}
            searchable={false}
            onResetClick={handleGroupFilterReset}
          />
        </EuiHeaderSectionItem>
      </EuiHeaderSection>

      <EuiHeaderSection>
        <EuiHeaderSectionItem>
          <AttributeActions />
        </EuiHeaderSectionItem>
      </EuiHeaderSection>
    </EuiHeader>
  )
}

export { AttributesHeader }
