import { type CategoryFilterTree } from '@features/types'
import { LABELS } from '@shared/config'
import { CategoryFilterCheckbox } from '@shared/ui'

import { type GetAttributesCategoryFilterTreeArgs } from './types'

export const getAttributesCategoryFilterTree = ({
  items,
  rootAttributesCount,
  hasRoot = false,
}: GetAttributesCategoryFilterTreeArgs): CategoryFilterTree[] => {
  const categoryFilterTree = items.map((item) => {
    const { id, name, childNodes, isExpanded, children, attributes_count } = item

    return {
      id: `category_${id}`,
      label: (
        <CategoryFilterCheckbox
          value={id}
          label={name}
          count={attributes_count}
          childNodes={childNodes}
        />
      ),
      isExpanded: isExpanded,
      children: children && getAttributesCategoryFilterTree({ items: children }),
    }
  })

  if (hasRoot) {
    categoryFilterTree.unshift({
      id: 'category_root',
      label: (
        <CategoryFilterCheckbox
          value="true"
          label={LABELS.ATTRIBUTES_ROOT_CATEGORY}
          count={rootAttributesCount || 0}
          isRoot
        />
      ),
      isExpanded: false,
      children: undefined,
    })
  }

  return categoryFilterTree
}
