import { type EuiBasicTableColumn } from '@elastic/eui'
import { type ApiValidator } from '@shared/api/types'
import { getDateColumnRender } from '@shared/lib'

export const VALIDATORS_COLUMNS: EuiBasicTableColumn<ApiValidator>[] = [
  {
    field: 'id',
    name: 'ID',
    width: '120px',
  },
  {
    // TODO: Заменить field на имя атрибута, когда будет готов бэк
    field: 'validated_attribute_name',
    name: 'Атрибут',
  },
  {
    field: 'created_at',
    name: 'Создан',
    render: getDateColumnRender<ApiValidator, 'created_at'>(),
  },
  {
    field: 'updated_at',
    name: 'Изменен',
    render: getDateColumnRender<ApiValidator, 'updated_at'>(),
  },
]
