import { useAttributeApi, useAttributeTitle } from '@entities/attributes'
import { useUpadateAttribute } from '@features/attributes'
import { useMeta } from '@shared/hooks'
import { Layout } from '@shared/ui/layouts'
import { AttributeHeader, AttributeInfo } from '@widgets/settings'
import { useParams } from 'react-router-dom'

export const AttributePage: ReactFc = () => {
  const { id: attributeId } = useParams()
  const attribute = useAttributeApi(attributeId!)
  const title = useAttributeTitle(attribute)

  const { updateAttribute, isAttributeSubmitting, isSaveButtonActive, methods } =
    useUpadateAttribute()

  useMeta({ title })

  return (
    <Layout
      header={
        <AttributeHeader
          updateAttribute={updateAttribute}
          isAttributeSubmitting={isAttributeSubmitting}
          isSaveButtonActive={isSaveButtonActive}
        />
      }
    >
      <AttributeInfo methods={methods} />
    </Layout>
  )
}
