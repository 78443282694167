import { type CategoryFilterTree, type CategoryFilterTreeItem } from '@features/types'
import { CategoryFilterCheckbox } from '@shared/ui'

const getCatalogCategoryFilterTree = (items: CategoryFilterTreeItem[]): CategoryFilterTree[] => {
  const categoryFilterTree = items.map((item) => {
    const { id, name, skus_count, childNodes, isExpanded, children } = item

    return {
      id: `category_${id}`,
      label: (
        <CategoryFilterCheckbox
          value={id}
          label={name}
          count={skus_count}
          childNodes={childNodes}
        />
      ),
      isExpanded: isExpanded,
      children: children && getCatalogCategoryFilterTree(children),
    }
  })

  return categoryFilterTree
}

export { getCatalogCategoryFilterTree }
